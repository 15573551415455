import axios from 'axios';
import qs from 'qs'; // 用于方便地将对象转换为对应格式的字符串
//引入进度条
import nprogress from 'nprogress';
//引入相关进度条的样式
import "nprogress/nprogress.css";

class AxiosRequest {
    constructor(baseURL) {
        // 创建axios实例，设置基础URL等通用配置
        this.instance = axios.create({
            baseURL: baseURL,
            timeout: 10000 // 超时时间可根据实际情况调整
        });

        // 设置请求拦截器
        this.instance.interceptors.request.use(
            config => {
                //进度条开始
                nprogress.start()
                // 在这里可以添加通用的请求头信息，比如添加认证token（示例）
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

                // 判断数据类型
                if (config.data instanceof FormData) {
                    // 文件上传
                    config.headers['Content-Type'] = 'multipart/form-data';
                } else if (typeof config.data === 'object') {
                    // 表单数据
                    // config.data = qs.stringify(config.data); // 使用qs库将数据对象转换为对应格式的字符串
                    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                } else {
                    // json字符串
                    config.data = qs.stringify(config.data); // 使用qs库将数据对象转换为对应格式的字符串
                    config.headers['Content-Type'] = 'application/json;charset=utf-8';
                }

                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        // 设置响应拦截器
        this.instance.interceptors.response.use(
            response => {
                //进度条结束
               nprogress.done()
                // 可以在这里对响应数据进行统一处理，例如直接返回数据部分
                return response.data;
            },
            error => {
                // 统一处理响应错误，根据不同的状态码进行相应操作
                if (error.response) {
                    const statusCode = error.response.status;
                    switch (statusCode) {
                        case 400:
                            console.log('请求参数错误');
                            break;
                        case 401:
                            console.log('未授权，请登录');
                            break;
                        case 403:
                            console.log('权限不足');
                            break;
                        case 404:
                            console.log('请求资源不存在');
                            break;
                        case 500:
                            console.log('服务器内部错误');
                            break;
                        default:
                            console.log(`请求出错，状态码：${statusCode}`);
                    }
                } else if (error.request) {
                    console.log('网络连接异常，请检查网络');
                } else {
                    console.log('请求配置出错', error.message);
                }
                return Promise.reject(error);
            }
        );
    }

    async get(url, params = {}) {
        try {
            const response = await this.instance.get(url, { params });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async post(url, data = {}) {
        try {
            const response = await this.instance.post(url, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async put(url, data = {}) {
        try {
            const response = await this.instance.put(url, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async delete(url, params = {}) {
        try {
            const response = await this.instance.delete(url, { params });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async uploadFile(url, file) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const response = await this.instance.post(url, formData, config);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

// 实例化
const axiosApi = new AxiosRequest('https://webapi.yuanbaijia.com'); 

// 暴露模块
export default axiosApi

