//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'

// 引入mixin
import {commonData} from './mixin'
// 使用mixin
Vue.mixin(commonData)

// 引入并注册全局组件 Adbox.name是引入模块的中的name
import NameCard from './components/NameCard'
import Adbox from './components/Adbox'
Vue.component(Adbox.name, Adbox);
Vue.component(NameCard.name, NameCard);

// 引入阿里适量图标库
import '@alifont/iconfont.css';
// 引入bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min'

// 引入动画
import 'animate.css' 

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

 // 引入全局样式文件，重置浏览器样式 最后引入 权重高
import '@css/reset.css'
//引入store
import store from './store'

//关闭Vue的生产提示
Vue.config.productionTip = false
//应用插件
Vue.use(VueRouter)

//创建vm
new Vue({
	el:'#app',
	render: h => h(App),
	router:router,
	store,
	beforeCreate() {
		// 全局事件总线
		Vue.prototype.$bus = this
	},
})
