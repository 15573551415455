//home模块专享的小仓库
//任何的小仓库都需要:state、mutations、actions、getters 

//引入请求函数
import { reqBannerList, reqCompanyInfo } from '@/api/index'
//可以处理业务逻辑【if、异步语句等等】
let actions = {
    //获取banner图的action
    async getBannerList({ commit, state, dispatch }) {
        try {
            //获取首页数据
            let req = await reqBannerList()
            commit("GETBANNERLIST", req.data)
        } catch (error) {
            console.error(error)
        }
    },
    async getCompanyInfo({ commit, state, dispatch }) {
        try {
            //获取公司数据
            let req = await reqCompanyInfo()
            commit("GETCOMPANYINFO", req.data_2)
        } catch (error) {
            console.error(error)
        }
    },
}

//唯一可以修改仓库数据地方
let mutations = {
    // 轮播图
    GETBANNERLIST(state, bannerList) {
        state.bannerList = bannerList
    },
    // 公司信息
    GETCOMPANYINFO(state, obj){
        state.phone = obj.phone
        state.copyright = obj.copyright
        state.address = obj.address
        state.phone = obj.phone
        state.wxCode = obj.wxCode
    },
    // 路由选中下标赋值
    SETACTIVE(state,active){
        state.activeRoute = active
    },
    // 下标++
    ACTIVEJIA(state){
        state.activeRoute++
    },
    // 下标--
    ACTIVEJIAN(state){
        state.activeRoute--
    }
}
//仓库计算属性
let getters = {};

//仓库存储数据的地方
let state = {
    // 顶部导航选中下标
    activeRoute:0,
    //首页轮播图的数据
    bannerList: [],
    // 版权
    copyright: '',
    // 地址
    address:'',
    // 公众号二维码
    owCode:'',
    // 电话号码
    phone:'',
    // 微信二维码
    wxCode:''
}

//对外暴露小仓库
export default {
    // 开启命名空间
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}




