<!--  -->
<template>
  <div class="topbar">
    <nav class="navbar navbar-expand-sm navbar-dark bg-dark fixed-top" @wheel="mouseEvent"
      :class="activeRoute == 0 ? 'navbar-avtive' : ''">
      <div class="container-fluid">
        <a class="navbar-brand" href="javascript:void(0)">
          <img src="@img/logob.png" alt="" class="logo" :class="activeRoute == 0 ? 'logo-active' : ''">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mynavbar">
          <ul class="navbar-nav me-auto">
            <li class="nav-item navtext" v-for="(item, index) in navbar " :class="activeRoute == 0 ? 'text' : ''">
              <a :class="activeRoute == index ? 'active nav-link' : 'nav-link'" @click="vueRoute(index)">{{ item }}</a>
            </li>
            <li>
              <span class="el-icon-delete-solid"></span>
            </li>
          </ul>

          <div class="myphone" :class="activeRoute == 0 ? 'myphone-active' : ''">
            <i class="iconfont icon-dianhua"></i>
            <span> {{ phone }}</span>
          </div>

          <div class="beian" :class="activeRoute == 0 ? 'beian-active' : ''">
            备案/许可证编号： <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023091603号</a>
          </div>
        </div>
      </div>
    </nav>

    <div class="dockbox">
      <div class="dock">
        <div class="item" @click="tabUP"><i class="bi bi-chevron-up"></i></div>
        <div class="item phonebox">
          <div class="phonenum">
            <div>咨询热线</div>
            <div>{{ phone }}</div>
          </div>
          <i class="bi bi-telephone"></i>
        </div>
        <div class="item weixinbox">
          <i class="iconfont icon-weixin"></i>
          <div class="weixincode">
            <div>
              <img :src="ossUrl+wxCode" alt="">
            </div>
            <div class="saoma">扫码加微信咨询</div>
          </div>
        </div>
        <div class="item" @click="tabDown"><i class="bi bi-chevron-down"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState ,mapMutations} from "vuex";
export default {
  name: "Hearder",
  data() {
    return {
      navbar: ['首页', '关于我们', '软件功能', '文章动态', '服务商']
    };
  },
  watch: {
    $route: {
      handler(to) {
        switch (to.name) {
          case 'home':
          case 'index':
            this.SETACTIVE(0)
            break
          case 'about':
          case 'company':
          case 'EnterpriseDirection':
          case 'Organizational':
          case 'Rrecruit':
          this.SETACTIVE(1)
            break
          case 'software':
          case 'AnnualFeeReminder':
          case 'CustomerManage':
          case 'OrderManagement':
          case 'DeclarationManage':
          case 'ProjectManagement':
          case 'More':
          this.SETACTIVE(2)
            break;
          case 'article':
          case 'AllNews': 
          case 'IndustryTrends':
          case 'SoftwareUpdate':
          case 'NewsDetail':
          this.SETACTIVE(3)
            break;
          case 'ServiceProvider':
          case 'CentralChina':
          case 'EastChina':
          case 'NorthChina':
          case 'SouthChina':
          case 'WesternChina':
          this.SETACTIVE(4)
            break;
        }
      }
    }
  },
  computed:{
    //方式一：自己直接读取
    // this.$store.state.home.copyright
    //方式二：借助mapState读取：
    ...mapState('home',['activeRoute','copyright','address','owCode','phone','wxCode'])
  },
  methods: {
    // this.$store.commit('home/SETACTIVE',1)
    //方式二：借助mapMutations 调用commit
    ...mapMutations('home',['SETACTIVE','ACTIVEJIA','ACTIVEJIAN']),

    vueRoute(index) {
      this.SETACTIVE(index)
      let routerPath = ["home", "company", "CustomerManage", "AllNews", "SouthChina"]
      this.$router.push({ name: routerPath[index] })
    },
    
    tabUP() {
      // activeRoute++
      this.ACTIVEJIAN()
      if (this.activeRoute < 0)  this.SETACTIVE(0)
      this.vueRoute(this.activeRoute)
    },

    tabDown() {
      let { navbar } = this
      // activeRoute--
      this.ACTIVEJIA()
      if (this.activeRoute > navbar.length - 1) this.SETACTIVE(navbar.length - 1) 
      this.vueRoute(this.activeRoute)
    },
    
    mouseEvent(event) {
      if (event.deltaY > 0) {
        this.tabDown()
      } else {
        this.tabUP()
      }
    }
  },

  mounted() {

  },
}
</script>
<style lang="scss" scoped>
.beian {
  a {
    outline: none !important;
    text-decoration: none !important;
  }
}

.phonenum {
  width: 120px;
  height: 120px;
  color: white;
  background-color: #111;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  top: 50%;
  right: -260px;
  transform: translateY(-50%);
  border-radius: 6px 0 0 6px;
  transition: all 1s ease-in-out;
}

.weixincode {
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  padding-top: 10px;
  background-color: #111;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
  top: 50%;
  right: -260px;
  transform: translateY(-50%);
  border-radius: 6px 0 0 6px;
  transition: all 1s ease-in-out;
  opacity: 1 !important;

  .saoma {
    position: absolute;
    font-size: 12px;
    color: white;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: -12px;
  }

  img {
    width: 80px;
  }
}

.phonebox:hover {
  color: white !important;

  .phonenum {
    right: 50px;
  }
}

.weixinbox:hover {
  color: white !important;

  .weixincode {
    right: 50px;
  }
}

.icon-weixin {
  font-size: 28px !important;
  font-weight: bold;
}

.d-flex {
  width: auto !important;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.navbar-brand {
  margin-left: 40px;
}

#mynavbar {
  margin-left: 40px;
}

.bi-chevron-up,
.bi-chevron-down,
.bi-telephone {
  font-size: 24px;
  font-weight: bold;
}

.dock {
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  right: 0px;
  width: 50px;
  -moz-opacity: 0.7;
  opacity: 0.7;
  z-index: 200;
  background-color: #111;
  border-radius: 6px;
}

.item {
  cursor: pointer;
  width: 100%;
  height: 60px;
  color: wheat;
  text-align: center;
  line-height: 60px;
}

.item:hover {
  color: white !important;
}

.nav-item {
  margin-left: 20px;
  transition: font-size 0.4s ease-in-out;
  cursor: pointer;
}



// 屏幕小于768px
@media screen and (max-width:768px) {
  //备案
  .beian {
    font-size: 12px;
    color: white;
    margin-right: 30px;
    transition: font-size 0.4s ease-in-out;
  }
  //备案过渡
  .beian-active {
    font-size: 14px !important;
    color: white;
    margin-right: 40px;
  }
  //导航条
  .navbar {
    font-size: 13px;
    height: 50px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  //导航条过渡
  .navbar-avtive {
    font-size: 16px !important;
    height: 60px;
  }
  // log正常
  .logo {
    height: 20px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 30px;
  }
  // 电话
  .myphone {
    margin-right: 10px;
    color: $theme_color;
    font-size: 18px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 18px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 24px !important;
    .icon-dianhua {
      font-size: 24px !important;
    }
  }
  #mynavbar{
    margin-left: 0;
  }
}

// 中等屏幕
@media screen and (min-width:768px) and (max-width:992px) {
//备案
.beian {
    font-size: 12px;
    color: white;
    margin-right: 30px;
    transition: font-size 0.4s ease-in-out;
  }
  //备案过渡
  .beian-active {
    font-size: 14px !important;
    color: white;
    margin-right: 40px;
  }
  //导航条
  .navbar {
    font-size: 13px;
    height: 50px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  //导航条过渡
  .navbar-avtive {
    font-size: 16px !important;
    height: 60px;
  }
  // log正常
  .logo {
    height: 20px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 30px;
  }
  // 电话
  .myphone {
    margin-right: 10px;
    color: $theme_color;
    font-size: 18px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 18px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 24px !important;
    .icon-dianhua {
      font-size: 24px !important;
    }
  }
  #mynavbar{
    margin-left: 0;
  }
}

// 屏幕大于768px 小于1281px
@media screen and (min-width:992px) and (max-width:1281px) {
   //备案
   .beian {
    font-size: 12px;
    color: white;
    margin-right: 30px;
    transition: font-size 0.4s ease-in-out;
  }
  //备案过渡
  .beian-active {
    font-size: 14px !important;
    color: white;
    margin-right: 40px;
  }
  //导航条
  .navbar {
    font-size: 13px;
    height: 50px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  //导航条过渡
  .navbar-avtive {
    font-size: 16px !important;
    height: 60px;
  }
  // log正常
  .logo {
    height: 20px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 30px;
  }
  // 电话
  .myphone {
    margin-right: 10px;
    color: $theme_color;
    font-size: 18px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 18px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 24px !important;
    .icon-dianhua {
      font-size: 24px !important;
    }
  }
  #mynavbar{
    margin-left: 0;
  }
}

// 屏幕大于1281px 小于1366px  14寸笔记本
@media screen and (min-width:1281px) and (max-width:1366px) {
  //备案
  .beian {
    font-size: 14px;
    color: white;
    margin-right: 40px;
    transition: font-size 0.4s ease-in-out;
  }
  //备案过渡
  .beian-active {
    font-size: 16px !important;
    color: white;
    margin-right: 40px;
  }
  //导航条
  .navbar {
    font-size: 14px;
    height: 60px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  //导航条过渡
  .navbar-avtive {
    font-size: 15px !important;
    height: 70px;
  }
  // log正常
  .logo {
    height: 30px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 40px;
  }
  // 电话
  .myphone {
    margin-right: 40px;
    color: $theme_color;
    font-size: 20px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 24px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 24px !important;
    .icon-dianhua {
      font-size: 26px !important;
    }
  }
}


// 屏幕大于1367px 小于1500px  19寸电脑
@media screen and (min-width:1367px) and (max-width:1500px){
  // 备案文字
  .beian {
    font-size: 14px;
    color: white;
    margin-right: 40px;
    transition: font-size 0.4s ease-in-out;
  }
  // 备案过渡
  .beian-active {
    font-size: 18px;
    color: white;
    margin-right: 40px;
    transition: font-size 0.4s ease-in-out;
  }
  // 导航条
  .navbar {
    font: size 14px ;
    height: 60px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  // 导航条过渡
  .navbar-avtive {
    font-size: 18px;
    height: 80px;
  }

  .myphone {
    margin-right: 20px;
    color: $theme_color;
    font-size: 25px !important;
    transition: height, font-size 0.2s ease-in-out;
  }
  // log正常
  .logo {
    height: 30px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 40px;
  }
  // 电话
  .myphone {
    margin-right: 10px;
    color: $theme_color;
    font-size:20px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 20px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 24px !important;
    .icon-dianhua {
      font-size: 24px !important;
    }
  }

}

// 屏幕大于1500px 21寸及以上
@media screen and (min-width:1500px)  {
  // 备案文字
  .beian {
    font-size: 16px;
    color: white;
    margin-right: 40px;
    transition: font-size 0.4s ease-in-out;
  }
  // 备案过渡
  .beian-active {
    font-size: 20px;
    color: white;
    margin-right: 40px;
    transition: font-size 0.4s ease-in-out;
  }
  // 导航条
  .navbar {
    height: 60px;
    // 高度发生变化时 使用过渡动画
    transition: height 0.4s ease-in-out;
  }
  // 导航条过渡
  .navbar-avtive {
    font-size: 20px;
    height: 80px;
  }
  .myphone {
    margin-right: 40px;
    color: $theme_color;
    font-size: 25px !important;
    transition: height, font-size 0.2s ease-in-out;
  }
  // log正常
  .logo {
    height: 30px;
    transition: height 0.4s ease-in-out;
  }
  // log过渡
  .logo-active {
    height: 40px;
  }
  // 电话
  .myphone {
    margin-right: 40px;
    color: $theme_color;
    font-size: 25px !important;
    transition: height, font-size 0.2s ease-in-out;
    .icon-dianhua {
      font-size: 25px !important;
      transition: height, font-size 0.2s ease-in-out 0.1s;
    }
  }
  // 电话过渡
  .myphone-active {
    font-size: 28px !important;
    .icon-dianhua {
      font-size: 28px !important;
    }
  }

}
</style>