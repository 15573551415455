<!--  -->
<template>
    <div class="NameCard">
        <div class="cardbox">
            <div class="nameCard" v-for="item in nameCard">
                <div class="leftbox">
                    <div class="name">{{item.name}}</div>
                    <div class="imgbox">
                        <img src="@/assets/images/gongsi.png" alt="">
                    </div>
                </div>
                <div class="centerbox">
                    <div class="company-name">{{item.company}}</div>
                    <div class="phonbox">
                        <span class="phone">电话：</span>
                        <span>{{item.phone}}</span>
                    </div>
                    <div class="adressbox">
                        <span class="address">地址：</span>
                        <span>{{item.address}}</span>
                    </div>
                </div>
                <div class="rightbox">
                    <ul>
                        <li>金蝶</li>
                        <li>用友</li>
                        <li>企小达</li>
                        <li>微星</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NameCard",
    components: {},
    data() {
        return {
           nameCard:[
            {
                name:'张三',
                company:'极哆客',
                phone:1366558979,
                address:'深圳市罗湖区解放路2001号太阳广场'
            },
            {
                name:'李四',
                company:'凌达信',
                phone:1366558979,
                address:'深圳市罗湖区解放路2001号太阳广场'
            },
            {
                name:'王五',
                company:'巨达来',
                phone:1366558979,
                address:'深圳市罗湖区解放路2001号太阳广场'
            },
           ]
        };
    },
    methods: {

    },
    mounted() {

    },
}
</script>
<style lang='scss' scoped>
.cardbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.nameCard {
    width: calc((100% - 40px) / 2);
    padding: 40px 20px;
    display: flex;
    margin-right: 40px;
    margin-top: 60px;
    align-items: center;
    position: relative;
    border: 1px solid $theme_color1;
    box-sizing: border-box
}

.nameCard:nth-child(2n) {
    margin-right: 0;
}

.leftbox {
    margin-right: 10px;
}

.imgbox {
    width: 100px;
    height: 100px;
    background-color: rgb(220, 221, 221);
}

.imgbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}

.company-name {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    background-color: $theme_color1;
    color: white;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}

.rightbox {
    position: absolute;
    right: 0;
    top: 0;
}

.rightbox ul li {
    background-color: $theme_color1;
    color: white;
    margin: 20px 0;
    padding: 4px 4px 4px 10px;
    border-radius: 10px 0 0 10px;
}
.phonbox{
    margin-bottom: 10px;
}
.phone ,.address{
    color: rgb(112, 111, 111);
}
</style>