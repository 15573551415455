//引入AxiosReques
import axiosApi from '@/utils/AxiosReques'

/**
 * @description 获取首页轮播图
 * @returns Promise
 */
export const reqBannerList = () => axiosApi.post('/index/index')
    
/**
 * @description 获取公司信息
 * @returns Promise
 */
export const reqCompanyInfo = () => axiosApi.post('/index/index')

/**
 * @description 获取公司信息
 * @returns Promise
 */
export const reqAboutBrief = () => axiosApi.post('/index/getAboutBrief')

/**
 * @description 企业方向
 * @returns Promise
 */
export const reqAboutDirection = () => axiosApi.post('/index/getAboutDirection')

/**
 * @description 组织架构
 * @returns Promise
 */
export const reqAboutFramework = () => axiosApi.post('/index/getAboutFramework')


/**
 * @description 软件功能-客户管理
 * @returns Promise
 */
export const reqSoftwareCustomer  = () => axiosApi.post('/index/getSoftwareCustomer')

/**
 * @description 软件功能-订单管理
 * @returns Promise
 */
export const reqSoftwareOrder  = () => axiosApi.post('/index/getSoftwareOrder')

/**
 * @description 软件功能-项目管理
 * @returns Promise
 */
export const reqSoftwareProject  = () => axiosApi.post('/index/getSoftwareProject')

/**
 * @description 软件功能-申报管理
 * @returns Promise
 */
export const reqSoftwareDeclaration  = () => axiosApi.post('/index/getSoftwareDeclaration')

/**
 * @description 软件功能-年费提醒
 * @returns Promise
 */
export const reqSoftwareFee  = () => axiosApi.post('/index/getSoftwareFee')

/**
 * @description 软件功能-更多
 * @returns Promise
 */
export const reqSoftwareMorePc  = () => axiosApi.post('/index/getSoftwareMorePc')

/**
 * @description 文章动态
 * @returns Promise
 * @params 分页参数
 */
export const reqArticle  = (params) => axiosApi.post(`/index/getArticle?p=${params.page}&menu=${params.menu}`)

/**
 * @description 文章详情
 * @returns Promise
 * @params 分页参数
 */
export const reqNewsDetail  = (params) => axiosApi.post(`/index/getNewsDetail?id=${params.id}`)
