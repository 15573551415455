
// 路由配置模块
export default [
    {
        path: '/',
        redirect: '/index',
        meta: {
            title: '首页'
        },
    },
    {
        name: 'home',
        path: '/index',
        component: () => import('@/pages/Index'),
        meta: { title: '首页' },
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('@/pages/About'),
        meta: { title: '关于我们' },
        children: [ //通过children配置子级路由
            {
                name: 'company',
                path: 'company',
                component: () => import('@/pages/About/Company'),
                meta: { title: '公司简介' }
            },
            {
                name: 'EnterpriseDirection',
                path: 'EnterpriseDirection',
                component: () => import('@/pages/About/EnterpriseDirection'),
                meta: { title: '企业方向' }
            },
            {
                name: 'Organizational',
                path: 'Organizational',
                component: () => import('@/pages/About/Organizational'),
                meta: { title: '组织架构' }
            },
            {
                name: 'Rrecruit',
                path: 'Rrecruit',
                component: () => import('@/pages/About/Rrecruit'),
                meta: { title: '人才招聘' }
            },

        ]
    },
    {
        name: 'software',
        path: '/software',
        component: () => import('@/pages/Software'),
        meta: { title: '软件功能' },
        children: [
            {
                name: 'AnnualFeeReminder',
                path: 'AnnualFeeReminder',
                component: () => import('@/pages/Software/AnnualFeeReminder'),
                meta: { title: '年费提醒-示例' }
            },
            {
                name: 'CustomerManage',
                path: 'CustomerManage',
                component: () => import('@/pages/Software/CustomerManage'),
                meta: { title: '客户管理-示例' }
            },
            {
                name: 'OrderManagement',
                path: 'OrderManagement',
                component: () => import('@/pages/Software/OrderManagement'),
                meta: { title: '订单管理-示例' }
            },
            {
                name: 'ProjectManagement',
                path: 'ProjectManagement',
                component: () => import('@/pages/Software/ProjectManagement'),
                meta: { title: '项目管理-示例' }
            },
            {
                name: 'DeclarationManage',
                path: 'DeclarationManage',
                component: () => import('@/pages/Software/DeclarationManage'),
                meta: { title: '申报管理-示例' }
            },
            {
                name: 'More',
                path: 'More',
                component: () => import('@/pages/Software/More'),
                meta: { title: '更多-示例' }
            },
        ]
    },
    {
        name: 'article',
        path: '/article',
        component: () => import('@/pages/Aticle'),
        meta: { title: '文章动态' },
        children: [
            {
                name: 'IndustryTrends',
                path: 'IndustryTrends',
                component: () => import('@/pages/Aticle/IndustryTrends'),
                meta: { title: '行业动态' },
                children: [
                    {
                        name: 'NewsDetail-1',
                        path: 'NewsDetail',
                        component: () => import('@/pages/Aticle/NewsDetail'),
                        meta: { title: '文章详情' },
                        // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                        props(route) {
                            return {
                                id: route.query.id
                            }
                        }
                    }
                ]
            },
            {
                name: 'SoftwareUpdate',
                path: 'SoftwareUpdate',
                component: () => import('@/pages/Aticle/SoftwareUpdate'),
                meta: { title: '软件更新' },
                children: [
                    {
                        name: 'NewsDetail-2',
                        path: 'NewsDetail',
                        component: () => import('@/pages/Aticle/NewsDetail'),
                        meta: { title: '文章详情' },
                        // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                        props(route) {
                            return {
                                id: route.query.id
                            }
                        }
                    }
                ]
            },
            {
                name: 'AllNews',
                path: 'AllNews',
                component: () => import('@/pages/Aticle/AllNews'),
                meta: { title: '全部' },
                children: [
                    {
                        name: 'NewsDetail-3',
                        path: 'NewsDetail',
                        component: () => import('@/pages/Aticle/NewsDetail'),
                        meta: { title: '文章详情' },
                        // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                        props(route) {
                            return {
                                id: route.query.id
                            }
                        }
                    }
                ]
            },

        ]
    },
    {
        name: 'ServiceProvider',
        path: '/ServiceProvider',
        component: () => import('@/pages/ServiceProvider'),
        meta: { title: '服务商' },
        children: [
            {
                name: 'CentralChina',
                path: 'CentralChina',
                component: () => import('@/pages/ServiceProvider/CentralChina'),
                meta: { title: '华中区' }
            },
            {
                name: 'EastChina',
                path: 'EastChina',
                component: () => import('@/pages/ServiceProvider/EastChina'),
                meta: { title: '华东区' }
            },
            {
                name: 'NorthChina',
                path: 'NorthChina',
                component: () => import('@/pages/ServiceProvider/NorthChina'),
                meta: { title: '华北区' }
            },
            {
                name: 'SouthChina',
                path: 'SouthChina',
                component: () => import('@/pages/ServiceProvider/SouthChina'),
                meta: { title: '华南区' }
            },
            {
                name: 'WesternChina',
                path: 'WesternChina',
                component: () => import('@/pages/ServiceProvider/WesternChina'),
                meta: { title: '华西区' }
            }
        ]
    }
]